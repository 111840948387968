<template>
    <aside class="transform top-0 left-0 w-70por md:w-56 bg-bo-aside fixed h-full ease-in-out transition-all duration-300 z-20"
           :class="sidebarIsOpen ? 'translate-x-0' : '-translate-x-full'">
        <div class="h-full flex flex-col">
            <div class="h-80por overflow-auto">
                <nav-left-top/>
            </div>
            <div class="h-20por">
                <nav-left-bottom/>
            </div>
        </div>
    </aside>
</template>

<script>
import NavLeftTop from "@/pages/inside/shared/navigation/NavLeftTop";
import NavLeftBottom from "@/pages/inside/shared/navigation/NavLeftBottom";
import {actions, state} from "@/store";

export default {
    name: "NavLeft",
    components: { NavLeftTop, NavLeftBottom },
    watch:{
        $route() {
            actions.setSidebarIsOpen(false)
        }
    },
    computed: {
        sidebarIsOpen() {
            return state.sidebarIsOpen;
        }
    },
}
</script>

<style scoped>

</style>