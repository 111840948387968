<template>
    <div class="fixed bottom-0 w-full text-gray-500">
        <div v-if="me && me.data.is_super_admin">
            <div class="border-b border-gray-700"></div>
            <div class="p-0 lg:p-2">
                <router-link :to="{ name: 'BackConnections' }" v-slot="{ isActive, navigate }">
                    <button @click="navigate" class="focus:outline-none flex cursor-pointer items-center p-2
                    hover:bg-bo-aside-items-hover hover:shadow-md hover:text-white rounded-md w-full"
                            :class="{'bg-bo-aside-items-selected text-gray-700 shadow-md': isActive }">
                        <i class="mdi mdi-transit-connection-horizontal text-2xl leading-none mx-2"></i>
                        <span class="text-sm">Conexiones</span>
                    </button>
                </router-link>
            </div>
        </div>
        <div v-if="me && me.data.is_super_admin">
            <div class="border-b border-gray-700"></div>
            <div class="p-0 lg:p-2">
                <a :href="vueAppApiUrl + '/horizon'" target="_blank"
                    class="focus:outline-none flex cursor-pointer items-center p-2
                    hover:bg-bo-aside-items-hover hover:shadow-md hover:text-white rounded-md w-full">
                    <i class="mdi mdi-transit-connection-horizontal text-2xl leading-none mx-2"></i>
                    Horizon
                </a>
            </div>
        </div>
        <div v-if="me && me.data.is_super_admin">
            <div class="border-b border-gray-700"></div>
            <div class="p-0 lg:p-2">
                <a :href="vueAppApiUrl + '/telescope'" target="_blank"
                   class="focus:outline-none flex cursor-pointer items-center p-2
                    hover:bg-bo-aside-items-hover hover:shadow-md hover:text-white rounded-md w-full">
                    <i class="mdi mdi-transit-connection-horizontal text-2xl leading-none mx-2"></i>
                    Telescope
                </a>
            </div>
        </div>
        <div v-if="me && me.data.is_super_admin">
            <div class="border-b border-gray-700"></div>
            <div class="p-0 lg:p-2">
                <a :href="vueAppApiUrl + '/apidocs'" target="_blank"
                   class="focus:outline-none flex cursor-pointer items-center p-2
                    hover:bg-bo-aside-items-hover hover:shadow-md hover:text-white rounded-md w-full">
                    <i class="mdi mdi-transit-connection-horizontal text-2xl leading-none mx-2"></i>
                    Api docs
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { actions } from "@/store";
export default {
    name: "AsideBottom",
    components: {},
    methods: {
        collapse() {
            actions.setSidebarIsOpen(false);
        },
    },
    computed: {
        me() {
            return actions.getUser();
        },
        sidebarIsOpen() {
            return actions.getSidebarIsOpen();
        },
        vueAppApiUrl() {
            return process.env.VUE_APP_API_URL;
        }
    },
}
</script>

<style scoped>

</style>