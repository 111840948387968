<template>
    <Request model="Auth" action="logout" ref="logoutRef">
        <button @click="logout" class="flex flex-row items-center w-full h-full focus:outline-none">
            <slot name="icon">
                <i class="mdi mdi-logout text-xl leading-none mx-2"></i>
            </slot>
            <span class="text-gray-600">Cerrar sesión</span>
        </button>
    </Request>
</template>


<script>
import {actions, state} from "@/store";
import {Request} from "@/api/components";
export default {
    name: "Logout",
    components: {
        Request
    },
    methods: {
        collapse() {
            state.sidebarIsOpen = false;
        },
        async logout() {
            await this.$refs.logoutRef.request();
            await actions.resetAuth();
            await this.$router.push({ name: 'Login' });
        },
    },
    computed: {
        sidebarIsOpen() {
            return state.sidebarIsOpen;
        }
    },
}
</script>

<style scoped>

</style>