<template>
    <div class="w-screen h-full text-gray-700 text-center">
        <Navigation v-if="routeNeedsNav"/>
        <router-view class="bg-bo-light-html h-full"/>
        <Request model="User" ref="deviceRef" action="device" :form="form" @success="onSuccess" @error="onError"/>
    </div>
</template>

<script>
import Navigation from "@/pages/inside/shared/navigation/Navigation";
import { Request } from '@/api/components';
import { actions } from "@/store";
import { Plugins } from '@capacitor/core';
const { PushNotifications, Device } = Plugins;

export default {
    name: "Inside",
    components: { 
        Navigation, Request
    },
    data() {
        return {
            show: false,
            form: {
                'os_version': null,
                'platform': null,
                'app_version': null,
                'app_build': null,
                'registration_id': null,
                'user_id': null
            },
            notification: null
        };
    },
    computed: {
        user() {
            return actions.getUser();
        },
        routeName() {
            return this.$route.name;
        },
        routeNeedsNav() {
            return !!this.boRouteNames.includes(this.routeName);
        }
    },
    methods: {
        async setDeviceInfo(deviceInfo) {
            this.form.os_version = deviceInfo.osVersion;
            this.form.platform = deviceInfo.platform;
            this.form.app_version = deviceInfo.appVersion;
            this.form.app_build = deviceInfo.appBuild;
            this.form.os_version = deviceInfo.osVersion;
            this.form.user_id = this.user.data.id;
        },
        setToken(registrationId) {
            this.form.registration_id = registrationId;
        },
        onSuccess(response) {
            console.log(response)
        },
        onError(errors) {
            this.toastErrors(errors)
        },
        fcmRegister() {
            PushNotifications.requestPermission().then(result => {
                if (!result.granted) {
                    console.error('Push Notifications could not be registered');
                    return;
                }
                PushNotifications.register();
            });
        },
        fcmRegistrationSuccessListener() {
            PushNotifications.addListener('registration',async (token) => {
                await this.setToken(token.value);
                await this.$refs.deviceRef.request();
            });
        },
        fcmRegistrationErrorListener() {
            PushNotifications.addListener('registrationError', (error) => {
                alert('Error on registration: ' + JSON.stringify(error));
            });
        },
        async fcm() {
            let deviceInfo = await Device.getInfo();
            if (deviceInfo.platform === 'web') {
                return;
            }
            await this.setDeviceInfo(deviceInfo);
            await this.fcmRegister();
            await this.fcmRegistrationSuccessListener();
            await this.fcmRegistrationErrorListener();
        }
    },
    mounted() {
        this.fcm();
    }
}
</script>

<style lang="scss" scoped>
</style>