<template>
    <nav class="flex fixed w-full items-center justify-between px-6 h-16 bg-main text-gray-700 border-b border-gray-400 z-10">
        <nav-top></nav-top>
        <nav-left></nav-left>
        <transition enter-class="opacity-0" enter-active-class="ease-out transition-medium" enter-to-class="opacity-100"
                    leave-class="opacity-100" leave-active-class="ease-out transition-medium" leave-to-class="opacity-0">
            <div v-show="sidebarIsOpen" class="z-10 fixed inset-0 transition-opacity">
                <div @click="collapse" class="absolute inset-0 bg-black opacity-50" tabindex="0"></div>
            </div>
        </transition>
    </nav>
</template>

<script>
import { actions } from "@/store";
import NavTop from "@/pages/inside/shared/navigation/NavTop";
import NavLeft from "@/pages/inside/shared/navigation/NavLeft";
export default {
    name: "Navigation",
    components: { NavTop, NavLeft },
    methods: {
        collapse() {
            actions.setSidebarIsOpen(false);
        }
    },
    computed: {
        sidebarIsOpen() {
            return actions.getSidebarIsOpen();
        }
    },
}
</script>

<style scoped>

</style>