<template>
  <div class="pl-8 pr-2 pt-1 flex flex-row items-center justify-start">
                        <span class="mr-2">
                            <i class="mdi mdi-chevron-right text-md"></i>
                        </span>

    <router-link :to="{ name: linkName }" v-slot="{ isActive, navigate }">
      <button @click="navigate" class="focus:outline-none flex cursor-pointer items-center p-2
                        hover:bg-bo-aside-items-hover hover:shadow-md hover:text-white rounded-md w-full"
              :class="{'bg-bo-aside-items-selected text-gray-700 shadow-md': isActive }">
        <i class="mdi text-2xl leading-none mx-2" :class="[icon]"></i>
        <span class="text-sm">{{ name }}</span>
      </button>
    </router-link>
  </div>
</template>

<script>
export default {
  props: ['name', 'linkName', 'icon'],

  name: "ButtonAdminNavegation"
}
</script>

<style scoped>

</style>