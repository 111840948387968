<template>
    <div class="flex w-full items-center justify-between items-center text-white">
        <div class="flex items-center justify-center">
            <button aria-label="Open Menu" @click="expandSideBar"
                    class="mr-2 focus:outline-none">
                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" class="w-8 h-8">
                    <path d="M4 6h16M4 12h16M4 18h16"></path>
                </svg>
            </button>
        </div>

        <img src="/img/logos/marina_de_empresas.png" alt="Logo" class="h-auto w-12 mt-1 mx-auto"/>

        <div v-if="user" class="relative inline-block text-left">
            <button @click="toggleDropdown" type="button" class="flex justify-center items-center w-full rounded-md px-3 py-2
                    text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500
                    w-12 h-12 rounded-full bg-gray-400 overflow-hidden truncate uppercase text-main"
                    id="options-menu" aria-haspopup="true" aria-expanded="true">
                {{ user.data.name.substring(0,3) }}
                <!-- <i class="mdi mdi-chevron-down text-xl leading-none mx-4 transform right-0 fixed ease-in-out transition-all duration-300"
                   :class="dropdown ? 'rotate-0' : 'rotate-90'"></i> -->
            </button>
            <div v-if="dropdown" class="z-20 origin-top-right absolute right-0 mt-1 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                    <span class="flex flex-row justify-left items-center text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer h-8">
                        <Logout/>
                    </span>
                </div>
            </div>
        </div>
        <transition enter-class="opacity-0" enter-active-class="ease-out transition-medium" enter-to-class="opacity-100"
                    leave-class="opacity-100" leave-active-class="ease-out transition-medium" leave-to-class="opacity-0">
            <div v-show="dropdown" class="z-10 fixed inset-0 transition-opacity">
                <div @click="cancelLogout" class="absolute inset-0 bg-black opacity-50" tabindex="0"></div>
            </div>
        </transition>
    </div>
</template>

<script>
import {actions} from "@/store";
import Logout from "@/pages/inside/shared/logout/Logout"
export default {
    name: "NavTop",
    components: { Logout },
    data() {
        return {
            dropdown: false,
            errors: null
        };
    },
    computed: {
        user() {
            return actions.getUser();
        },
        sidebarIsOpen() {
            return actions.getSidebarIsOpen();
        },
        routeName() {
            return this.$route.name;
        },
    },
    methods: {
        expandSideBar() {
            if (this.boAdminRouteNames.includes(this.routeName)) {
                actions.setSectionsIsExpanded(true);
            }
            actions.setSidebarIsOpen(!this.sidebarIsOpen)
        },
        cancelLogout() {
            this.dropdown = false;
        },
        toggleDropdown() {
            this.dropdown = !this.dropdown;
        }
    },
}
</script>

<style scoped>

</style>