<template>
    <div class="text-gray-500">
        <span @click="collapse" class="flex w-full justify-center items-center h-16">
            <img src="/img/logos/logo_edem.svg" alt="Logo" class="h-6 w-full mx-auto"/>
        </span>
        <div class="mb-2 border-b border-gray-700"></div>
        <div class="px-2">
            <router-link :to="{  name: 'ExecutiveSummary' }">
                <button class="w-full focus:outline-none flex cursor-pointer items-center p-2 hover:bg-bo-aside-items-hover hover:shadow-md hover:text-white rounded-md">
                    <i class="mdi mdi-chart-donut text-2xl leading-none mx-2"></i>
                    <span>Dashboard</span>
                </button>
            </router-link>
        </div>
        <div class="m-2 border-b border-gray-700"></div>
        <div v-if="user && user.data.is_admin">
            <div class="px-2">
                <button @click="toggleSectionsIsExpanded" class="w-full focus:outline-none flex cursor-pointer items-center p-2
            hover:bg-bo-aside-items-hover hover:shadow-md hover:text-white rounded-md">
                    <i class="mdi mdi-account-cog text-2xl leading-none mx-2"></i>
                    <span>Administración</span>
                    <i class="mdi mdi-chevron-down text-2xl leading-none mx-4 transform right-0 fixed ease-in-out transition-all duration-300"
                       :class="sectionsIsExpanded ? 'rotate-0' : 'rotate-90'"></i>
                </button>
            </div>
            <transition name="slide-fade">
                <div v-if="sectionsIsExpanded">
                  <button-admin-navigation name="Áreas" linkName="BackAreas" icon="mdi-folder-multiple-outline"></button-admin-navigation>
                  <button-admin-navigation name="Áreas Importe" linkName="BackAreaAmounts" icon="mdi-folder-star-multiple-outline"></button-admin-navigation>
                  <button-admin-navigation name="Cursos" linkName="BackCourses" icon="mdi-school-outline"></button-admin-navigation>
                  <button-admin-navigation name="Ediciones" linkName="BackEditions" icon="mdi-calendar-multiple-check"></button-admin-navigation>
                  <button-admin-navigation name="Listas HP" linkName="BackHpLists" icon="mdi-hubspot"></button-admin-navigation>
                  <button-admin-navigation name="Usuarios" linkName="BackUsers" icon="mdi-account-group"></button-admin-navigation>
                  <button-admin-navigation name="Notificaciones" linkName="BackNotifications" icon="mdi-cellphone-message"></button-admin-navigation>
                </div>
            </transition>
            <div class="m-2 border-b border-gray-700"></div>
        </div>
    </div>
</template>

<script>
import ButtonAdminNavigation from "@/pages/inside/shared/components/ButtonAdminNavegation.vue";

import {actions} from "@/store";
export default {
    name: "NavLeftTop",
    components:{
      ButtonAdminNavigation
    },
    watch:{
        $route() {
            actions.setSidebarIsOpen(false)
        }
    },
    methods: {
        collapse() {
            actions.setSidebarIsOpen(false)
        },
        toggleSectionsIsExpanded() {
            actions.setSectionsIsExpanded(!this.sectionsIsExpanded);
        },
    },
    computed: {
        user() {
            return actions.getUser();
        },
        sidebarIsOpen() {
            return actions.getSidebarIsOpen();
        },
        sectionsIsExpanded() {
            return actions.getSectionsIsExpanded();
        }
    },
}
</script>

<style scoped>
</style>